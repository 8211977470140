import * as React from 'react';
import { withLayout } from '../components/layout.tsx';
import { Button, List, Header, Segment } from 'semantic-ui-react';
import creditData from '../../external_repositories/open_source_compliance/output/credits.json';

interface DependencyProps {
  license: string;
  website: string;
}

class Dependency extends React.Component<
  DependencyProps & { name: string },
  { expanded: boolean }
> {
  constructor(props: DependencyProps & { name: string }) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleExpandedState() {
    this.setState({ expanded: !this.state.expanded });
  }

  public render() {
    return (
      <Segment color="orange">
        <span css={{ fontSize: '120%' }}>{this.props.name}</span>
        <a target="_blank" href={this.props.website}>
          <Button color="orange" floated="right" compact size="tiny">
            Visit Website
          </Button>
        </a>
        <Button
          compact
          color="orange"
          size="tiny"
          floated="right"
          onClick={() => this.toggleExpandedState()}
        >
          Show License
        </Button>
        {this.state.expanded && (
          <pre
            css={{
              padding: '2ex',
              overflow: 'auto',
            }}
          >
            {this.props.license}
          </pre>
        )}
      </Segment>
    );
  }
}

class Credits extends React.Component {
  public render() {
    return (
      <>
        <Header as="h1">Credits for Mondaic's Downloader</Header>

        <Header as="h2">
          <a
            href="#downloader-dependencies"
            css={{ marginTop: '-50px', paddingTop: `50px` }}
            id="downloader-dependencies"
          >
            Mondaic Downloader Dependencies
          </a>
        </Header>
        <p>
          Mondaic's downloader is written in Rust and ships with the following
          open-source packages:
        </p>
        {Object.entries(creditData.rust_dependencies).map((c, index) => {
          return (
            <Dependency
              key={index}
              name={c[0]}
              license={c[1].license}
              website={c[1].website}
            />
          );
        })}
      </>
    );
  }
}

export default withLayout(Credits);
